<template>
  <div class="login-container">
    <el-form ref="loginFormRules" :rules="loginFormRules" :model="formInfo" class="login-form">
      <div class="title">
        <h3>南大教育管理系统</h3>
      </div>
      <el-form-item prop="username">
        <el-input v-model="formInfo.username" placeholder="账号">
          <i slot="prefix" class="iconfont icon-user-fill"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="formInfo.password" type="password" placeholder="密码">
          <i slot="prefix" class="iconfont icon-lock-fill"></i>
        </el-input>
      </el-form-item>
      <el-button type="primary" @click="loginHandle">登录</el-button>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formInfo: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions('login', ['login']),
    /* 用户登录 */
    loginHandle () {
      this.$refs.loginFormRules.validate(valid => {
        if (!valid) return false
        /* 请求登录接口 */
        this.login(this.formInfo).then(res => {
          window.sessionStorage.setItem('token', res.token)
          this.$router.push('/home')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  min-height: 100vh;
  background-color: #00142a;
  .login-form {
    position: absolute;
    width: 400px;
    max-width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title h3 {
      text-align: center;
      margin-bottom: 40px;
      font-size: 36px;
      color: #eee;
      font-weight: 700;
      letter-spacing: 10px;
    }
    .el-button {
      width: 100%;
      background-color: #1890ff;
      margin-top: 20px;
      &:hover {
        background-color: #46a6ff;
      }
    }
    .el-form-item {
      background: rgba(0,0,0,.1);
      border: 1px solid hsla(0,0%,100%,.1);
      border-radius: 5px;
      .el-input {
        padding: 5px 0;
        margin: 0 10px;
        ::v-deep .el-input__inner {
          background: transparent;
          color: #fff;
          border: none;
        }
        ::v-deep .el-input__prefix {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
